

.cic-admin-top-navbox
{
    height:60px;
    background-color: #5A6474;

    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);

    .topbox
    {
        height:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        

        .right,.left
        {
            display: flex;
            flex-direction: row;

            align-items: center;
            align-content: center;
            *
            {
                color:white;
            }
        }

        .admin-cate
        {
            padding:0px;
            height: 100%;
            > li
            {
                font-size:18px;
                display: inline-flex;
                margin-left:20px;
                height: 100%;
                
                a
                {
                    padding-left:5px;
                    padding-right:5px;
                    padding-top: 10px;
                    display: flex;
                    align-items: center;
                    height: calc( 100% - 2px );
                    color:#ccc;
                    
                    &:hover
                    {
                        text-decoration: none;
                        color:white;
                    }

                    
                    &.active
                    {
                        font-size: 18px;
                        color:white;
                        border-bottom: 5px solid white;
                    }
                }

                
            }
        }
    }
}

.cic-admin-box
{
    .title
    {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 18px;
    }

    .content
    {
        display: flex;
        flex-direction: row;

        

        .main
        {
            flex:6;
        }

        .side
        {
            flex:2;
            max-width:240px;
            min-width:240px;
            margin-left:20px;
            height: fit-content;
        }
    }
}

.shadowbox
{
    &:not(:empty)
    {
        background-color: white;
        // padding:20px;
        border-radius: 5px;

        border:1px solid #eee;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
        &:hover
        {
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
        }

    }
}

.padbox
{
    padding:20px;
}

.cic-table
{
    border-top: 1px solid #f2f2f2;
    width: calc( 100% + 40px );
    position: relative;
    left:-20px;
    // width:100%;

    thead *, tfoot *
    {
        color:#ccc!important;
    }

   td
   {
        vertical-align: middle!important;
        font-size: 14px;
        .cic-icon
        {
            svg
            {
              max-width: 14px;
              max-height: 14px;
            }
        }
        
        &.actions > *
        {
            margin-left:10px;
        }

        &.center
        {
            text-align: center;
        }
   }
}

.cic-clean
{
    margin-bottom: 0px!important;
    // padding-left:0px!important;
}

.cic-checkbox-col
{
    max-width:50px;
}

.cic-sidemenu
{
    font-size:16px;

    

    display: flex;
    align-items: center;

}


.cic-icon
{
    margin-right:5px;
        svg
        {
            color:#ccc;
        }
}

.cic-bread-nav
{
    display: flex;
    align-items: center;

    a
    {
        &:hover
        {
            text-decoration: none;
        }
    }

    color:#aaa;

    svg
    {
        margin-left:5px;
        color:#aaa;
    }
}

.row
{
    display: flex;
    flex-direction: row;
}

.chapter-index
{
    font-size: 18px;
    > li
    {
        padding-top:5px;
        padding-bottom:5px;
    }
}

.cic-row
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > *
    {
        flex:1;
        &:not(:first-child)
        {
            margin-left:10px;
        }
    }

    &:not(:first-child)
    {
        margin-top:10px;
    }
}

.cic-flex-rows
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > *
    {
        flex:1;
    }
}

.cic-f1
{
    flex:1;
}

.cic-f2
{
    flex:2;
}

.cic-f3
{
    flex:3;
}

.unpublished
{
    color:#ccc;
    & a
    {
        color:#ccc; 
    }
}

.used
{
    text-decoration: line-through;
}

.DateInput_input
{
    padding-top:6px!important;
    padding-bottom:6px!important;
    // border-radius: 5px!important;
}

.cic-cmd-editor
{
    .editor-menu
    {
        height:40px;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        padding-left:10px;
        padding-right:10px;
        > *
        {
            display: flex;
            align-items: center;
            margin-right:10px;
            cursor: pointer;

            // &:not(:first-child)
            // {
            //     &::before
            //     {
            //         content:"";
            //         color:#ddd;
            //         margin-right:5px;
            //     }
            // }
            

            color:black;
            a
            {
                color:black;
            }
        }

        
    }
    
    .SplitPane
    {
        height: calc(100vh - 100px)!important;

        .Resizer
        {
            background: #000;
            opacity: .1;
            
            &:hover {
                -webkit-transition: all 2s ease;
                transition: all 2s ease;
            }

            &.vertical {
                width: 7px;
                border-left: 3px solid rgba(255, 255, 255, 0);
                border-right: 3px solid rgba(255, 255, 255, 0);
                cursor: col-resize;
            }
        
            &.vertical:hover {
                border-left: 3px solid rgba(0, 0, 0, 0.5);
                border-right: 3px solid rgba(0, 0, 0, 0.5);
            }
            &.disabled {
              cursor: not-allowed;
            }
            &.disabled:hover {
              border-color: transparent;
            }
        }
    }
}