
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/scss/variables";


// ==============================
// cic

body
{
    min-height: 100vh;
    background-color: #F2F2F2;
}

.bg-drak
{
    background-color: #656F7F;
}

.cic-overlay
{
    .overbox
    {
        width:100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .centerbox
        {
            max-width:400px;
            min-width:400px;
            min-height:100px;
            background-color: white;
            border-radius: 5px;
            padding:20px;
        }
    }
}

.cic-form-actionbar
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cic-center-box
{
    // background-color: antiquewhite;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cic-full-height
{
    min-height:100vh;
    background-color: antiquewhite;
}

.cic-fullscreen-height
{
    min-height:100vh;
    max-height:100vh;
    overflow-y: hidden;
    // background-color: red;
}

.overbox
{
    .cic-form
    {
        border-radius: 5px;
        max-width:600px;
        padding:20px;
        background-color: white;
        .content
        {
            height:200px;
            overflow-y: scroll;
            overflow-x: hidden;
            margin-top:10px;
            padding:10px;
            border:1px solid #eee;
            width:100%;
            min-width:400px;

            color:white;
            background-color: black;
            line-height: 1.8;
            font-size:16px;
        }

        .right
        {
            text-align: right;
        }
    }
}

.cic-show-height
{
    height:100%;
    // background-color: antiquewhite;
}

.cic-show-tab-height
{
    height:calc( 100vh - 60px );
    // background-color: red;
}


.cic-wrapper
{
    max-width:1000px;
    margin-left: auto ;
    margin-right: auto ;
}

.cic-show-box
{
    
    height: calc( 100vh - 60px );
    overflow: hidden;

    .whiteindex 
    {
        background-color: white;
        height: calc(100vh - 90px)!important;
        display: flex;
        align-items: flex-start;
        overflow-y: auto;
        
        > *
        {
            width:100%;
        }

        .std-index-readonly
        {
            width:100%;

            .indexbox-item
            {
                padding-top:10px!important;
                padding-bottom:10px!important;
            }

            .section
            {
                width:100%!important;
                padding-bottom:10px!important;
            }
        }
    }

    .bp3-tabs
    {
        height:100%;
    }
    

    .bp3-tab-list
    {
        // margin-left:10px;
        // background-color: red;
    }

    .bp3-tab-panel
    {
        margin-top: 0px;
        height:calc( 100% - 30px ) ;
    }

    .action-icon
    {
        > *:not(:last-child)
        {
            // margin-right:5px;
        }
    }



    .toolbar.half40
    {
        width:40%!important;
    }

    .toolbar.full
    {
        width:100%!important;
    }
   

    .SplitPane
    {
        height: calc(100vh - 90px)!important;

        .Resizer
        {
            background: #000;
            opacity: .1;
            
            &:hover {
                -webkit-transition: all 2s ease;
                transition: all 2s ease;
            }

            &.vertical {
                width: 7px;
                border-left: 3px solid rgba(255, 255, 255, 0);
                border-right: 3px solid rgba(255, 255, 255, 0);
                cursor: col-resize;
            }
        
            &.vertical:hover {
                border-left: 3px solid rgba(0, 0, 0, 0.5);
                border-right: 3px solid rgba(0, 0, 0, 0.5);
            }
            &.disabled {
              cursor: not-allowed;
            }
            &.disabled:hover {
              border-color: transparent;
            }
        }
    }
}


.cic-json-page
{
    width:100%;
    display: flex;
    flex-direction: column;
    flex:1;
    background-color: white;
    // position: relative;

    // &.editor
    // {
    //     height: calc( 100vh - 100px );
    // }

    .acast-box
    {
        
        
        

        // pre
        // {
        //     display: inline-block;
        // }
    }
    
    .SplitPane
    {
        width:auto!important;
        position: relative!important;
        .horizontal.Pane2
        {
            height:100%;
            // background-color: #656F7F;
            overflow-y: auto;
        }  

        .Resizer
        {
            background: #000;
            opacity: .2;
            z-index: 1;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -moz-background-clip: padding;
            -webkit-background-clip: padding;
            background-clip: padding-box;

            &:hover {
                -webkit-transition: all 2s ease;
                transition: all 2s ease;
            }
        
             &.horizontal {
                height: 11px;
                margin: -5px 0;
                border-top: 5px solid rgba(255, 255, 255, 0);
                border-bottom: 5px solid rgba(255, 255, 255, 0);
                cursor: row-resize;
                width: 100%;
            }

            &.vertical {
                width: 11px;
                margin: 0 -5px;
                border-left: 5px solid rgba(255, 255, 255, 0);
                border-right: 5px solid rgba(255, 255, 255, 0);
                cursor: col-resize;
            }
        
            &.vertical:hover {
                border-left: 5px solid rgba(0, 0, 0, 0.5);
                border-right: 5px solid rgba(0, 0, 0, 0.5);
            }
        }
    }
    
    

    .top
    {
        // position: relative;
        // display: flex;
        // // padding:20px;
        // align-items: center;
        // justify-items: center;
        overflow-y: auto;
        // background-color: #656F7F;
        width:100%;
        text-align: center;
        vertical-align: center;
        .image-box
        {
            // display: flex;
            width:100%;
            height: 100%;
            // background-color: antiquewhite;
            
            // border: solid red 1px;
            img
            {
                // border:1px solid blue;
                max-width: 100%; 
                max-height: 100%;
            }
        }
    }

    .bottom
    {
        width: content-box;
        position: relative;
        overflow-y: auto;
        // background-color: antiquewhite;
        // height:100%;
        .toolbar
        {
            max-height:50px;
            // border-top:1px solid #ddd;
            padding:10px;
            display: flex;
            width:50%;
            justify-content: space-between;
            position: fixed;
            background-color: rgba(255, 255, 255, .8);
            z-index:10;

            .right
            {
                > *
                {
                    margin-left:10px;
                }
            }

        }
        .content
        {
            margin-top: 50px;
            // max-width:50%!important;
            // background-color: #656F7F;
            // overflow-y: hidden;
            // padding:10px;
            img
            {
                max-width:100%;
            }

            font-size: 16px;
            line-height: 1.6;

            .block
            {
                position: relative;
                overflow-y: auto;

                .textaction
                {
                    > *
                    {
                        padding:10px;

                        &:hover,&.readclick
                        {
                            background-color: antiquewhite;
                            
                        }
                    }
                        
                    // p
                    // {
                        
                        
                    // }
                }

                .codeaction
                {
                    background-color:#fcfcfc;
                    margin-bottom: 20px;
                    padding:10px;
                    // border-radius: 5px;
                    // border: 1px solid #eee;
                    &:hover,&.readclick
                    {
                        background-color: antiquewhite;
                    }
                }

                pre.codeblock
                {
                    border-radius: 3px;
                    
                    // max-width: 50%!important;
                    overflow-x: scroll;
                    
                    code
                    {
                        max-width:200px!important;
                        // overflow-x: hidden;
                        display:inline-block;
                    }                    
                }

                
            }


            
        }
    }

    

    // > *
    // {
    //     flex:1;
    // }

    

    // .top
    // {
    //     position: relative;
    //     max-height: 50%;
    //     display: flex;
    //     // padding:20px;
    //     align-items: center;
    //     justify-items: center;
    //     // overflow-y: auto;
    //     .image-box
    //     {
    //         display: flex;
    //         width:100%;
    //         background-color: antiquewhite;
    //         justify-items: center;
    //         justify-content: center;
    //         img
    //         {
    //             position: relative;
    //             border:1px solid #eee;
    //             max-width: 100%;
    //             max-height: 300px;
    //         }
    //     }
        
    // }

    // .content
    // {
    //     padding:10px;
        
    // }

    
}

.cic-lesson-exbox
{
    margin-bottom:10px;
    font-size: 16px;
}

.cic-myindex-page
{
    .std-index-readonly
    {
        width:100%;

        .indexbox-item
        {
            padding-top:10px!important;
            padding-bottom:10px!important;
        }

        .section
        {
            width:100%!important;
            padding-bottom:10px!important;
        }
    }
}

.cic-mylesson
{
    
    
    .cic-mylesson-line
    {
        padding-bottom:20px;
        padding-top:20px;
        
        &:first-child
        {
            padding-top: 10px;
        }
        

        display: flex;
        flex-direction: row;

        .line-button
        {
            border: 1px solid #eee;
            color:#aaa;
            svg
            {
                color:#aaa;
            }

            &:hover
            {
                color:#333;
                svg
                {
                    color:#333;
                }
            }
        }

        .right
        {
            max-width:180px;
        }

        .left
        {
            flex:1;
        }

        .mytitle
        {
            font-size:20px;
            margin-bottom: 5px;
        }
        
        &:not(:last-child)
        {
            border-bottom:1px solid #eee;
        }
    }
}

.left-5
{
    margin-left:5px;
}

.left-10
{
    margin-left:10px;
}

.right-5
{
    margin-right:5px;
}

.right-10
{
    margin-right:10px;
}

.bottom-10
{
    margin-bottom:10px;
}

.right-20
{
    margin-right:20px;
}

.font-18
{
    font-size:18px;
}

.font-16
{
    font-size:16px;
}

.browser-box
{
    width:100%;
    height:100%;
    border:0px;
    // background-color: blue;
}

.vps-box
{
    height:100vh;
    .password
    {
        position: absolute;
        right:0px;
        top:0px;
        z-index:9;
        padding:5px;
        svg
        {
            fill:#aaa;
            cursor: pointer;
        }
        opacity: .5;
    }

    .frame
    {
        width:100%;
        border:0px;
    }
}


// ==============================



$sj_bg:#F2F2F2;
$sj_black:#333333;
$sj_gray:#AAAAAA;
$sj_light_gray:#EEEEEE;
$sj_blue:$blue1;
$sj_box_border:#DDDDDD;
$sj_box_radius:5px;

$sj_title1:24px;
$sj_title2:18px;
$sj_title3:16px;
$sj_title4:14px;
$sj_title5:12px;
$sj_title6:9px;

$sj_normal_text: #666666;

$sj_title_weight:400;

html
{
    background-color: $sj_bg;
}

body
{
    margin: 0px;
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

h1,h2,h3,h4,h5
{
    font-weight: 400;
    font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

h1
{
    font-size: 32px;
}

.m50
{
    margin-top:50px;
}
.mr5
{
    margin-right:5px;
}

.ml5
{
    margin-left:5px;
}

.init-button-box
{
    text-align: center;
    .explain
    {
        margin-top: 10px;
        color:red;
    }
}

.m30
{
    margin-top:30px;
}

.explain
{
    color:$sj_gray;
}

.extive
{
    color:$sj_black;
}


.center-box
{
    // border: 1px solid red;
    max-width:900px;
    min-height:600px;
    // margin-left: auto;
    // margin-right: auto;
    flex:1;
    justify-content:center;
    align-items:center;
}

.connect-box
{
    display: flex;
    // background-color: antiquewhite;
    justify-content: center;
}

.qrimg-box
{
    img
    {
        // border:10px white solid;
        max-width:200px;
    }
}

.section-qr
{
    position: relative;
    .close-button
    {
        position: absolute;
        right:-30px;
        top:-30px;
        cursor: pointer;
        z-index:999;

        svg
        {
            fill:white;
            color:white;
        }
    }
}

.lesson_title_large
{
    color:white;
    font-size: 24px;

    svg
    {
        color:#999;
    }
}

.exchage-page
{
   display: flex;
   justify-content:center;
   align-items:center;
   height:calc( 100vh - 80px );
   
   .exchange-box
   {
       max-width: 600px;
       min-width: 200px;
       min-height:200px;
    //    background-color: antiquewhite;

       display: flex;
       flex-direction: row;

       .left-logo
       {
           max-width:140px;
           img
           {
               max-width:140px;
               max-height:140px;
           }
       }

       .right-content
       {
           flex:1;
           padding:20px;

           .notice
           {
               color:white;
               font-size: 20px;
               line-height: 1.8;
               a
               {
                   color:white;
                   border-bottom: 1px solid white;
                   padding-bottom: 3px;

                   &:hover
                   {
                        border-bottom: 0px;
                        text-decoration: none;
                   }
               }
           }

           .action
           {
               > *
               {
                   margin-right:20px;
                   margin-bottom:20px;
               }

               .wide
               {
                   min-width: 160px;
               }

               .line-button
               {
                   border: 1px solid white;
                   color:white;
                   margin-top:20px;
               }
           }
       }
   } 
}



.center-wrapper
{
    display: flex;
    justify-content:center;
    align-items:center;
    height: 99vh;
}

// ======== login =======
.login-box
{
    background-color: white;
    border: 1px solid $sj_box_border;
    border-radius: $sj_box_radius;
    padding:20px;
    min-height: 400px;
    text-align: center;
    .qrcode
    {
        width:240px;
    }
}

.login-logo
{
    position: relative;
    top:40px;
    left:320px;

}

%shadow
{
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);
}

%shadow-heavy
{
    box-shadow: 3px 6px 10px rgba(0, 0, 0, .16);
    transition: box-shadow 0.5s ease-in-out;
}

.std-header
{
    position: fixed;
    width:100%;
    z-index: $pt-z-index-content;
    
    @extend %shadow;
    
    background-color: rgba(255, 255, 255, .9);
    height:60px;

    h1.title
    {
        color:$sj_blue;
        font-size: $sj_title1;
    }

    .header-box
    {
        >*
        {
            display: flex;
            align-items:center; 
        }
        flex:1;
        display: flex;
        flex-direction: row;
        .title
        {
            flex:1;
            min-width:180px;
        }
        .menu
        {
            flex:6;
        }
        .action
        {
            flex:1;
            display: flex;
            justify-content: flex-end;
        }
    }
}

.wrapper
{
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}

.center-v
{
    display: flex;
    align-items:center; 
}

.center-h
{
    display: flex;
    justify-content:center;
}

%center-h
{
    justify-content:center;
}

.left-logo-bg
{
    background-image: url(/image/logo.small-64.png);
    background-position: 0% -40%;
    background-repeat: no-repeat;
    padding-left:90px;
    padding-top:10px;
    &:hover
    {
        background-position: 0% -60%;
    }
}

.std-box
{
    position: relative;
    top:60px;
    .page-title
    {
        padding-top:20px;
        padding-bottom:20px;
        span
        {
            font-size: $sj_title2;
            color:$sj_gray;
            font-weight:$sj_title_weight;
            margin-right: 10px;
            &.active
            {
                color:$sj_blue;
            }
        }
    }

    .row
    {
        display: flex;
        flex-direction: row;
        .main
        {
            flex:8; 
            margin-bottom:40px;   
        }
        .side
        {
            min-width: 240px;
            flex:2;
            padding-left:20px;
            margin-bottom:40px; 
        }

        .wrapper:not(:empty)
        {
            &:not(:hover)
            {
                @extend %shadow;
            }
            &:hover
            {
                @extend %shadow-heavy;
            }
    
            background-color: white;
            border: 1px solid $sj_box_border;
            border-radius: $sj_box_radius;
            padding:20px;
        }
    }

    
}

.std-top-menu
{
    margin: 0px;
    padding:0px;
    li
    {
        display: inline-block;
        color:$sj_gray;
        font-size: $sj_title3;
        font-weight: $sj_title_weight;
        &:not(:last-child)::after
        {
            content: '·';
            color: $sj_light_gray;
            margin-left:3px;
            margin-right:3px;
        }
        a
        {
            color:$sj_gray;
        }
        a.actived
        {
            color:$sj_blue!important;
        }
    } 
}

.avatar-icon
{
    max-width: 32px;
    max-height: 32px;
    border-radius: 100%;
}

.avatar-name
{
    font-size: $sj_title4;
    color:$sj_gray;
    margin-left:10px;
}

.info-box
{
    color:$sj_gray;
    @extend %center-h;
    display: flex;
    flex-direction: column;
}

.eheader
{
    height:60px;
    &.header
    {
        background-image: url(/image/logo.small-64.png);
        background-position: 20px -40%;
        background-repeat: no-repeat;
        &:hover
        {
            background-position: 20px -60%;
        }

        .right
        {
            max-width:200px;
            display: flex;
            align-items: center;
        }
    }
    
    .nav
    {
        display: inline-flex;
        align-items: center;
        font-size: 20px;
        margin-left: 100px;
        
    }
}

.std-form
{
    img.cover
    {
        width:120px;
        height:120px;
        border:1px solid $sj_box_border;
        border-radius: $sj_box_radius;
    }

    margin-bottom: 50px;
    .preview
    {
        background-color: #F2F2F2;
        width:100%;
        position: relative;
        left:-20px;
        top:-20px;
        width:calc( 100% + 40px );
        padding:30px;
        img
        {
            max-width: 100%;
        }

    }
}

.std-hr
{
    border-top:1px solid $sj_light_gray;
    margin-top: 20px;
    padding-bottom: 20px;
}

.form-div
{
    position: relative;
    left:-20px;
    width:calc( 100% + 40px );
}

.row-half
{
    display: flex;
    flex-direction: row;
    >*
    {
        display: flex;
        flex:1;
        &:last-child
        {
            justify-content: flex-end;
        }
    }
}


span.std-date
{
    padding:3px;
    padding-left:6px;
    padding-right:6px;
    background-color:$blue2;
    color:white;
    border-radius: $sj_box_radius;
    &:first-child
    {
        margin-right:10px;
    }
    &:last-child
    {
        margin-left:10px;
    }
}

span.std-input
{
    background-color: $gray5;
    padding:5px;
    color:white;
    border-radius: 3px;
    position: relative;
    top:12px;
    right:5px;
}

.discount.row-half
{
    .left
    {
        flex:5;
    }
    .right
    {
        flex:5;
        min-width:300px; 
    }
}

input.short_input
{
    width:30px;
}

.std-area
{
    width:100%;
    min-height: 120px;

    &.long
    {
        min-height: 400px;
    }
}

.std-row
{
    display: flex;
    flex-direction: row;
}

.image-select-row
{
    align-items: flex-end;
}

.edit-box
{
    display: flex;
    flex-direction: row;
    > *
    {
        flex:1;
        margin-right:5px;
    }
}

.image-box-preview
{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    > *
    {
        margin-right: 5px;
    }
    img
    {
        border:1px solid #eee;
        padding:1px;
    }
}

.p5
{
    padding:5px;
}



.fs-editor
{
    display: flex;
    width:100%;
    height:100%;
    
    .fs-edit
    {
        flex:1;
        background-color: white;
        .toolbar
        {
            display: flex;
            flex-direction: row;
            margin-bottom: 2px;
            margin-top: 2px;
            height: 35px;
            padding:5px;
            align-items: center;
        }

        .fs-textarea
        {
            width:100%;
            height: calc( 100vh - 47px );
            border: 0px;

        }


    }

    .fs-preview
    {
        flex:1;
        background-color: $sj_bg;
        padding:40px;
        height: 100%;
        overflow-y: scroll;

        img
        {
            max-width: 100%;
        }
    }
}

.std-float
{
    position: absolute;
    width:400px;
    height:300px;
    background-color: white;
}

.lesson-box-item
{
    border-bottom: 1px solid $sj_box_border;
    position: relative;
    left:-20px;
    width:calc( 100% + 40px );
    padding:20px;

    &:last-child
    {
        border:0px;
    }
    .row
    {
       

        display: flex;
        flex-direction: row;

        .coverbox
        {
            flex:1;
            max-width:98px;
            height:98px;
            border: 1px solid $sj_box_border;
            border-radius: $sj_box_radius;
            margin:10px;

            .cover
            {
                width:96px;
                height:96px; 
                border-radius: $sj_box_radius;
            }
        }

        .desp
        {
            padding-left:10px;
            padding-right:10px;
            flex:1;
            .name
            {
                color:$sj_blue;
                font-size:$sj_title1;
                margin-bottom: 5px;
            }
            .title
            {
                color:$sj_gray;
                font-size: $sj_title3;
            }
            .priceline
            {
                margin-top:5px;
                margin-bottom: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                > *
                {
                    margin-right:5px;
                }
                .price
                {
                    font-size:$sj_title2;
                    color:$sj_blue;
                    font-family: Georgia, Times, 'Times New Roman', serif; 
                }
                .price.discount
                {
                    font-size:$sj_title2;
                    color:$sj_gray;
                    font-family: Georgia, Times, 'Times New Roman', serif;
                    text-decoration:line-through; 
                }
                
                .member_count
                {
                    color:$sj_gray;
                }
            }

            .discount_price_span
            {
                padding:3px;
                padding-left:6px;
                padding-right:6px;
                color:white;
                background-color: $blue5;
                border-radius: 2px;
                font-size: 12px;
            }

            
        }
    }

    .updating-desp
    {
        margin-top:15px;
        color: $sj_blue;
        font-size:$sj_title2;
    }
    

    
}

.lesson-detail-page
{
    .despbox
    {
        font-size: $sj_title2;
        color: $sj_normal_text;
        line-height: 160%;
    }
}

.indexbox-item,.section
{
    display: flex;
    .row
    {
        width:100%;
        .left
        {
            flex:2;
        }

        .right
        {
            flex:1;
            width: 150px;
        }
    }
    
    .action
    {
        
        visibility: hidden;
        display: flex;
        align-items: center;
        align-content:center;
        justify-content: flex-end;

        > span
        {
            cursor: pointer;
            margin-right:10px;

            display: flex;
            align-items: center;
            align-content:center;
            
        }
        .icon
        {
            margin-right:2px;
        }
    }
    &:hover .action
    {
       visibility: visible;
       
    }
}


.std-index
{
    ul.chapter-index
    {
        width:100%;
        margin:0px;
        padding:0px;
        color:$sj_gray;
        
        list-style: none;
        > li
        {
            display: flex;
            align-items: center;
            padding-top:8px;
            padding-bottom:8px;
            
            &:not(:last-child)
            {
                border-bottom: 1px solid $sj_light_gray;
            }

            &.section,&.section-add
            {
                margin-left:26px;
            }
        }

        
    }

    li.chapter,li.chapter-add
    {
        font-size: $sj_title2;
        .sort-btn
        {
            color:$sj_gray;
            svg{ fill:$sj_gray; }
        }
    }

    li.section,li.section-add
    {
        font-size: $sj_title3;
        .sort-btn
        {
            color:$sj_gray;
            svg{ fill:$sj_gray; }
        }
    }
    
}

.slient-div
{
    &:not(:hover) a
    {
        color:$sj_gray;
        transition: color 0.5s ease-in-out;
    }
    &:hover a
    {
        color:$sj_blue;
        transition: color 0.5s ease-in-out;
    }
}

.overbox
{
    width:100%;
    min-height:100vh;
    
    display:flex;
    align-items: center;
    justify-content: center;
    .bg
    {
        background: white;
        width:600px;
        min-height: 300px;
        padding:20px;
    }
    
    .btn
    {
        cursor: pointer;
        position: relative;
        
        svg
        {
            fill:$sj_gray;
        }
    }

    .tright
    {
        text-align: right;
    }
}

.orderbox-list
{
   margin:0px;
   margin-top:10px;
   padding:0px;
   li
   {
       display: flex;
       padding:10px;
       margin-bottom: 10px;
       border: 1px solid $sj_box_border;
       cursor: move;  
       user-select:none;
   }
}

.video-player
{
    .off-button
    {
        svg
        {
            fill:rgba(255, 255, 255, .6);
        }

        position: absolute;
        right:20px;
        top:20px;
        cursor: pointer;
        z-index:999;
    }
}

.global-video
{
    width:100%;
    height:100%;
    display:flex;
    align-items: center;
    justify-content: center;
}

.no-bottom-border
{
    border:0px;
}

*:focus
{
    outline: none;
}


.no-data
{
    width:400px;
    height:240px;
    margin-left:auto;
    margin-right:auto;
    margin-top:100px;
    margin-bottom:100px;
    border:1px solid $sj_box_border;
    border-radius: $sj_box_radius;
    position: relative;
    justify-content: center;
    display: flex;
    .man
    {
        width:160px;
        position: absolute;
        bottom:0px;
        right:10px;
    }
    .text
    {
        margin-top:40px;
        font-size:$sj_title1;
        font-weight: 300;
        color:$sj_gray;
    }
}

.wideone-layout
{
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    
    .header
    {
        
        
        flex:1;
        max-height: 60px;
        display: flex;
        align-items: center;

        width:100%;
        z-index: $pt-z-index-content;
        
        // @extend %shadow;
        
        background-color: white;
        border-bottom: 1px solid $sj_box_border;

        display: flex;
        flex-direction: row;

        h1.title
        {
            color:$sj_blue;
            font-size: $sj_title1;
            display: flex;
            align-items: center;
        }

        .left
        {
            flex:4;
            padding-left:10px;
        }

        .right
        {
            flex:6;
            display: flex;
            justify-content: flex-end;
            padding-right:10px;

            .backlink
            {
                font-size: $sj_title2;
                color:$sj_gray;
                display: flex;
                align-items: center;
            }
        }
    }
    .body
    {
        display: flex;
        flex-direction: row;
        flex:10;
        background-color: antiquewhite;
        .left
        {
            flex:7;
            background-color: $sj_light_gray;
            display:flex;
            flex-direction: row;

            .playerbox
            {
                flex:5;
                background-color: white;
                border-right: 1px solid $sj_box_border;
            }

            .planebox
            {
                max-width:50%;
                .action-icon
                {
                    display: flex;
                    align-items: center;
                    svg
                    {
                        fill:#AAAAAA;
                    }

                    .icon
                    {
                        margin-top:8px;
                        margin-right:8px;
                        cursor: pointer;
                    }
                    
                    

                    .terminal-switch
                    {
                        color:white;
                        background-color: $blue5;
                        border-radius: 2px;
                        padding:3px;
                        margin-top: 4px;
                        cursor: pointer;
                    }
                }
                
                flex:5;
                .article,.code
                {
                    overflow-y: auto;
                    padding:10px;
                    background-color: #FDF7E7;
                    height:100%;

                    > *
                    {
                        margin-bottom: 10px;
                    }

                    .at-image
                    {
                        img
                        {
                            max-width: 400px;
                            max-height: 1000px;
                            border-radius: 5px;
                            &:hover
                            {
                                box-shadow: 3px 6px 10px rgba(0, 0, 0, .16);
                                transition: box-shadow 0.5s ease-in-out;
                            }
                        }
                    }

                    pre.code
                    {
                        background-color: black;
                        color:$blue5;
                        padding:15px;
                        border-radius: $sj_box_radius;
                        cursor: pointer;
                        max-width: 600px;
                    }

                    // .article-end
                    // {
                    //     height: 10px;
                    //     background-color:red;
                    //     width:100%;
                    // }

                    
                }

                .terminal
                {
                    .terminal-box
                    {
                        margin-top:30px;
                    }
                }
            }

            .tabs
            {
                margin-top:5px;
                .bp3-tab-panel
                {
                    margin-top:0px;
                    border-top:1px solid $sj_box_border;
                    background-color: white;
                    height: calc( 100vh - 100px );
                }

                .bp3-tab-list
                {
                    margin-left:10px;
                }

                
            }

            .player
            {
                padding:20px;
            }
        }
        .right
        {
            flex:3;
            background-color: white;
            border-left: 1px solid $sj_box_border;
            max-width: 450px;
            min-width: 450px;
            .box
            {
                padding:20px;
                padding-bottom:0px;
                .title
                {
                    font-size: $sj_title2;
                    color:$sj_blue;
                }
            }
            .section-info-box
            {
                padding:10px;
                // min-height: 60px;
                .bp3-breadcrumbs
                {
                    height:auto;
                }

                .bp3-breadcrumb
                {
                    height:22px;
                }

                .float-right
                {
                    .icon
                    {
                        cursor: pointer;
                        fill:$sj_gray;
                        &:hover
                        {
                            fill:$sj_blue;
                        }
                    }
                }
            }
            
        }
    }
}

.std-index-readonly
{
    .chapter-index
    {
        margin:0px;
        margin-top:5px;
        padding:0px;
        font-size: $sj_title2;
        color:$sj_gray;
        position: relative;
        .indexbox-item
        {
            padding-top:5px;
            padding-bottom:5px;
        }
        .section
        {
            font-size: $sj_title3;
            padding:10px;
            padding-bottom: 0px;
            padding-left:46px;
            position: relative;
            left: -20px;
            width:calc( 100% + 40px );
            
            &.active
            {
                background-color: $blue5;
                color:white;
            }
        }
    }
}

.scripteditor
{
    .scriptbox
    {
        height: calc( 100vh - 105px );
        display: flex;
        flex-direction: column;
        border-left:1px solid $sj_box_border;
        .toolbar
        {
            background-color: $sj_bg;
            max-height: 40px;
            flex:1;
            padding:10px;
            padding-left:20px;
            border-bottom:1px solid $sj_box_border;
            color:$sj_gray;
            display: flex;
            align-items: center;

            > *
            {
                display:flex;
            }

            .icons
            {
                flex:1;
                display: inline;
                margin-left:10px;
                span
                {
                    cursor: pointer;
                    margin-right:5px;
                }
            }

            .righticon
            {
                flex:1;
                justify-content: flex-end;
            }
        }

        .actionbar
        {
            flex:1;
            max-height: 280px;
            bottom:0px;
            border-top: 1px solid $sj_box_border;
            background-color: white;

            .float-right
            {
                margin-right: 8px;
                margin-top: 5px;
            }

            .tabs
            {
                .bp3-tab-panel
                {
                    margin-top:0px;
                    border-top:1px solid $sj_box_border;
                    background-color: white;
                }

                .bp3-tab-list
                {
                    margin-left:10px;
                }
            }

            .textbox
            {
                width:100%;
                height:160px;
            }

            .rolelist
            {
                height:200px;
                overflow-y: auto; 
                margin: 0px;
                padding:10px;
                > li
                {
                    float:left;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width:80px;
                    height: 80px;
                    border:1px solid $sj_box_border;
                    margin:5px;

                    .thumb
                    {
                        max-width:75px;
                        max-height:75px;
                    }
                }
            }

        }

        .scriptlist
        {
            flex:10;
            overflow-y: auto;
            margin-top:0px;
            margin-bottom: 0px;
            list-style-type: decimal;
            word-wrap: break-word;
            padding:0px;
            li
            {
                padding:5px;
                border-bottom: 1px solid $sj_box_border;
                cursor: pointer;
                user-select: none;

                &:hover,&.active
                {
                    background-color: $blue5;
                    color:white;
                }

                span.type
                {
                    background-color: $blue5;
                    color:white;
                    padding:2px;
                    padding-left:5px;
                    padding-right:5px;
                    border-radius: 3px;
                }

                // > div.active
                // {
                //     background-color: $blue5;
                //     color:white;
                //     position: relative;
                //     // top:-5px;
                //     // left:-5px;
                //     // width:calc( 100% + 10px );
                //     padding:5px;
                // }
            }
        }
    }
    
}

.p20
{
    padding:20px;
}

.top5
{
    margin-top:5px;
}

.top10
{
    margin-top:10px;
}

.left5
{
    margin-left:5px;
}

.mb10{
    margin-bottom:10px;
}

.float-right
{
    float:right;
}

.std-row
{
    display: flex;
    flex-direction: row;
    // width:80%;
    // .left
    // {
    //     flex:5;
    // }
    // .right
    // {
    //     flex:5;
    // }
}

.full-height
{
    .bp3-input-group.bp3-large .bp3-input
    {
        height: 100%;
        line-height: 100%;
    }

}

.sjcode
{
    .short
    {
        width:100px;
    }
    .badge
    {
        background-color: $sj_blue;
        color:white;
        padding:5px;
        position: relative;
        top:15px;
        right:8px;
        border-radius: 5px;
    }
    .time
    {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $sj_title2;
        border:1px solid $sj_box_border;
        background-color: $sj_bg;
        padding-left:5px;
        padding-right:5px;
    }
}

.sj-code-list
{
    padding:0px;
    list-style-type: none;
    li
    {
        display: flex;
        flex-direction: row;
        padding-top:10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $sj_box_border;
        &:last-child {   border-bottom: 0px; } 
        > *
        {
            flex:1;
            display: flex;
            align-items: center;
        }
        .codetext
        {
            max-width:200px;
            min-width:200px;
            width:200px;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
        }
        .time
        {
            margin-left:20px;
        }
        .actions
        {
            display: flex;
            justify-content: flex-end;
        }
        
        &.sent 
        {
            color:$sj_gray;
        }

        &.disabled
        {
            color:red;
        }
    }
}



.toggle-bar
{
    display: flex;
    flex-direction: row;
    padding:5px;
    padding-top:10px;
    color:black;
    background-color: $sj_bg;
    border-bottom: 1px solid $sj_box_border;
    .left-side
    {
        flex:7;
        display: flex;
        flex-direction: row;
    }
    .right-side
    {
        flex:1;
        max-width: 20px;
        span
        {
            cursor: pointer;
        }
    }
    
}

.CalendarDay__selected_span {
background: $gray5; //background
color: white; //text
border:1px solid $gray4;
}

.DateInput_input__focused
{
    border-bottom: 1px solid $blue3;
}


.CalendarDay__selected {
background: $blue4;
color: white;
border:1px solid $gray4;
}

.CalendarDay__selected:hover {
background: $blue2;
color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
background: $blue4;
}


