
$header-height:60px;
$big-menu-size:18px;
$big-menu-color:#cccccc;
$big-menu-color-active:white;


.cic-site-box
{
    min-height: 100vh;
}

.cic-center-logo-box
{
    margin-top: $header-height;
    
    text-align: center;
    
    img
    {
        max-width:360px;
        max-height: 360px;
    }

    .subtitle
    {
        color:white;
        font-size: 32px;
        margin-bottom: 20px;
    }

    .explain
    {
        font-size: 24px;
        color:#aaa;
        letter-spacing: 6px;
    }

}


.cic-site-content
{
    // margin-top: $header-height;
    min-height: calc( 100vh - #{$header-height} ) ;
}

.cic-head-menu-box
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > *
    {
        display: flex;
        align-items: center;
    }

    .menu-list
    {
        > li
        {
            font-size:$big-menu-size;
            display: inline-flex;
            margin-left:20px;

            a
            {
                color:$big-menu-color;

                &:hover
                {
                    text-decoration: none;
                    // border-bottom: 1px solid white;
                }

                &.active
                {
                    color:$big-menu-color-active;
                }
            }

            
        }
    }
}

.cic-half-screen
{
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo-small
    {
        img
        {
            max-width: 250px;
            max-height: 250px;
        }

        .subtitle
        {
            color:white;
            text-align: center;
            margin-bottom: 20px;
            font-size: 24px;
        }

        height:320px;
    }

    .thebox
    {
        min-height: calc( 100vh - 400px );
        background-color: #F2F2F2;
        padding-top: 100px;
        padding-bottom: 100px;

        text-align: center;

        img
        {
            max-width: 200px;
            margin-bottom: 20px;
        }
        width:100%;
    }
}